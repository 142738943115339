import { Suspense } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";


export default function WorkoutList() {

    const loaderData : any = useLoaderData()
    
    return (
    <div >
        <div><h1>Select a Workout</h1></div>
        <div>
        <Suspense fallback={<div>
                    <h1>Loading workout data...</h1>
                    <ClimbingBoxLoader color="#574f6e"/>
                    </div>}>
            <Await resolve={loaderData.data}>
                {(resolvedWorkouts) => {
                    return resolvedWorkouts.map(function(d: any, idx: any){
                        return (<li key={idx}><Link to={`/details/${d.id}/wattsduringworkout`}>{d.date} {d.distance}m</Link></li>)
                    })
                }}
            </Await>
        </Suspense>
        </div>
    </div>
    );
}