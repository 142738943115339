import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_REGRESSION_COLOR, VEGA_WIDTH } from "../vegahelpers";


export default function PaceOverTime() {
    const data : any = useLoaderData()
    return vegaView(data, ((metadata) => { return {
        config: VEGA_CONFIG,
        vconcat: [{
            width: VEGA_WIDTH,
            height: VEGA_HEIGHT,    
        transform: [{calculate: "datum.time * 50 / datum.distance", as: "Average Pace"}],
        encoding: {
            
        },
        
        layer: [ 
            {
                params: [{
                    "name": "selectedTypes",
                    "select": {"type": "point", "fields": ["comments"]},
                    "bind": "legend"
                  }],
                mark: 'circle',
                title: {
                    text: "Pace per Workout over Time",
                    subtitle: "Shows how your pace has improved"
                },
                encoding: {
                    x: { field: 'date', type: 'temporal', axis: { title: "Date of Workout"}, 
                        scale: {domain: {param: "timeBrush"}},
                    },
                    y: { field: 'Average Pace', type: 'quantitative', sort: "descending", axis: { formatType: 'secondsAsPace'},
                        scale: { domainMin: metadata.paceAxisMin, domainMax: metadata.paceAxisMax } 
                    },
                    color: {field: "comments"} ,
                    tooltip: [
                        {field: "Average Pace", type: "quantitative", formatType: "secondsAsPace"},
                        {field: "date", type: "temporal"},
                        {field: "distance", type: "quantitative"},
                        {field: "comments"}
                    ],
                    opacity: {
                        condition: {"param": "selectedTypes", "value": 1},
                        value: 0.2
                      }
                },
            },
            {
                mark: { type: "line", color: VEGA_REGRESSION_COLOR},
                transform: [
                    {filter: {param: "selectedTypes"}},
                    {filter: {param: "timeBrush"}},
                    {
                        regression: "Average Pace",
                        on: "date"
                    }
                ],
                encoding: {
                    x: { field: 'date', type: 'temporal' ,
                    scale: {domain: {param: "timeBrush"}},
                    },
                    y: { field: 'Average Pace', type: 'quantitative', sort: "descending"},
                }
            },
            {
                mark: { 
                    type: "text", 
                    color: VEGA_REGRESSION_COLOR,
                    x: "width",
                    y: -5,
                    align: "right"
                },
                transform: [
                    {filter: {param: "selectedTypes"}},
                    {filter: {param: "timeBrush"}},
                    {
                        regression: "Average Pace",
                        on: "date",
                        params: true
                    },
                    {calculate: "'R²: '+format(datum.rSquared, '.2f')", as: "Params"}
                ],
                encoding: {
                    text: {type: "nominal", field: "Params"}
                }
            }
        ],
        }, {
            "width": VEGA_WIDTH,
            "height": 60,
            "mark": "bar",
            "params": [{
              "name": "timeBrush",
              "select": {"type": "interval", "encodings": ["x"]}
            }],
            "encoding": {
              "x": {
                "field": "date",
                "type": "temporal",
                timeUnit: "yearweek",
                axis: { title: "Week of Workout", format: "%Y-%m-%d"}
              },
              "y": {
                "aggregate": "count",
                "type": "quantitative",
                "axis": {"tickCount": 2, "grid": false, title: "Workouts"}
              }
            }
          }
        ],
        data: { name: 'table' }, 
    }}), null, "Tip: select a region on the lower graph to focus on a particular time range. Click a comment in the legend to filter to specific workout types.")
}