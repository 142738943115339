import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";



export default function EfficiencyDuringWorkout() {
    const data : any = useLoaderData()
    return vegaView(data, ((_) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [
            {calculate: " 2.8 / (pow(datum.p / 5000, 3)) / datum.spm", as: "Efficiency"}
        ],
        mark: 'trail',
        title: {
            text: "Efficiency during Workout",
            subtitle: ["Efficiency = watts / stroke rate", "Time in workout excludes rest"]
        },
        encoding: {
            x: { field: 'totalTime', type: "quantitative", axis: { title: "Time in Workout", formatType: 'strokeTimeAsMinutesSeconds'},
                scale: { domainMin: 0}
            },
            y: { field: "Efficiency", type: "quantitative", scale: { domainMin: 0, domainMax: 10}},
            size: {field: "spm"},
            tooltip: [
                {field: "Efficiency"},
                {field: "totalTime"}
            ]
        },
        data: { name: 'table' }, 
    }}))
}