import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";
import { Workout } from "../concept2";
import { secondsAsPace, tenthsOfSecondToMinutes } from "../vegahelpers";


export default function WeeklySummary() {

    const loaderData : any = useLoaderData()
    
    return (
    <div >
        <div><h1>Weekly Prergress</h1></div>
        <div>
        <Suspense fallback={<div>
                    <h1>Loading workout data...</h1>
                    <ClimbingBoxLoader color="#574f6e"/>
                    </div>}>
            <Await resolve={loaderData.data}>
                {drawWeeklySummary}
            </Await>
        </Suspense>
        </div>
    </div>
    );
}

function drawWeeklySummary(resolvedWorkouts: [Workout]) {
    const today = new Date()
    console.log(today.toLocaleDateString())
    console.log(today.getDay())
    var sunday = new Date()
    sunday.setDate(today.getDate() - today.getDay())

    sunday = new Date(sunday.toLocaleDateString())


    const previousSunday = subtractWholeDays(sunday, 7)
    const previousPreviousSunday = subtractWholeDays(sunday, 14)
    const lastWeekEnd = subtractWholeDays(sunday, 1)
    const previousWeekEnd = subtractWholeDays(previousSunday, 1)

    const lastWeekWorkouts : Workout[] = filterWorkoutsByDate(resolvedWorkouts, previousSunday, sunday)
    const previousWeekWorkouts : Workout[] = filterWorkoutsByDate(resolvedWorkouts, previousPreviousSunday, previousSunday)

    const numLastWeek = lastWeekWorkouts.length
    const numPreviousWeek = previousWeekWorkouts.length

    var numChange = "N/A"
    var longestChange = "N/A"
    var fastestChange = "N/A"
    var totalDistanceChange = "N/A"
    var totalTimeChange = "N/A"
    if (numLastWeek > 0 && numPreviousWeek > 0){
        numChange = calculateDeltaPercent(numLastWeek, numPreviousWeek).toFixed(1)
        longestChange = calculateDeltaPercent(longestDistance(lastWeekWorkouts), longestDistance(previousWeekWorkouts)).toFixed(1)
        fastestChange = (-calculateDeltaPercent(fastestPace(lastWeekWorkouts), fastestPace(previousWeekWorkouts))).toFixed(1)
        totalDistanceChange = calculateDeltaPercent(totalDistance(lastWeekWorkouts),  totalDistance(previousWeekWorkouts)).toFixed(1)
        totalTimeChange = calculateDeltaPercent(totalTime(lastWeekWorkouts), totalTime(previousWeekWorkouts)).toFixed(1)
    }

    var lastWeekLongest = "N/A"
    var lastWeekFastest = "N/A"
    var lastWeekTotalDistance = "N/A"
    var lastWeekTotalTime = "N/A"
    if (numLastWeek > 0){
        lastWeekLongest = longestDistance(lastWeekWorkouts).toString()
        lastWeekFastest = secondsAsPace(fastestPace(lastWeekWorkouts))
        lastWeekTotalDistance = totalDistance(lastWeekWorkouts).toString()
        lastWeekTotalTime = tenthsOfSecondToMinutes(totalTime(lastWeekWorkouts))
    }

    var previousWeekLongest = "N/A"
    var previousWeekFastest = "N/A"
    var previousWeekTotalDistance = "N/A"
    var previousWeekTotalTime = "N/A"
    if (numPreviousWeek > 0){
        previousWeekLongest = longestDistance(previousWeekWorkouts).toString()
        previousWeekFastest = secondsAsPace(fastestPace(previousWeekWorkouts))
        previousWeekTotalDistance = totalDistance(previousWeekWorkouts).toString()
        previousWeekTotalTime = tenthsOfSecondToMinutes(totalTime(previousWeekWorkouts))
    }





    return (
        <div>
            <table id="comparisontable">
                <tr><th></th><th>Last week<br/>{previousSunday.toLocaleDateString()} - {lastWeekEnd.toLocaleDateString()}</th><th>Change</th><th>Previous week<br/> {previousPreviousSunday.toLocaleDateString()} - {previousWeekEnd.toLocaleDateString()}</th></tr>
                <tr><td className="lh">Workouts</td><td>{numLastWeek}</td><td>{changeCell(numChange)}</td><td> {numPreviousWeek}</td></tr>
                <tr><td className="lh">Longest Distance</td><td>{lastWeekLongest}</td><td>{changeCell(longestChange)}</td><td> {previousWeekLongest}</td></tr>
                <tr><td className="lh">Fastest Pace</td><td>{lastWeekFastest}</td><td>{changeCell(fastestChange)}</td><td> {previousWeekFastest}</td></tr>
                <tr><td className="lh">Total Distance</td><td>{lastWeekTotalDistance}</td><td>{changeCell(totalDistanceChange)}</td><td> {previousWeekTotalDistance}</td></tr>
                <tr><td className="lh">Total Time</td><td>{lastWeekTotalTime} min</td><td>{changeCell(totalTimeChange)}</td><td> {previousWeekTotalTime} min</td></tr>
            </table>
            
        </div>
    )
}

function changeCell(changePercent: string){
    if (changePercent === "N/A"){
        return (<div >{changePercent}</div>)
    } else {
        const good = Number.parseFloat(changePercent) >= 0
        return (<div style={{color: (good ? 'green' : 'red' )}}>{good ? '⇧':'⇩'} {changePercent}%</div>)
    }
}


function subtractWholeDays(date: Date, days: number){
    var result = new Date()
    result.setDate(date.getDate() - days)
    result = new Date(result.toLocaleDateString())
    return result
}

function filterWorkoutsByDate(workouts: [Workout], after: Date, before: Date) {
    var result: Workout[] = new Array<Workout>()
    for (const workoutIndex in workouts) {
        const workout = workouts[workoutIndex]
        const workoutDate = new Date(workout.date)
        if (workoutDate > after && workoutDate < before) {
            result.push(workout)
        }
    }
    return result
}

function longestDistance(workouts: Workout[]) {
    var result = 0
    for (const workoutIndex in workouts) {
        const workout = workouts[workoutIndex]
        if (workout.distance > result) {
            result = workout.distance
        }
    }
    return result
}

function fastestPace(workouts: Workout[]) {
    var result = 10000000
    for (const workoutIndex in workouts) {
        const workout = workouts[workoutIndex]
        if ((workout.averagePace ?? 99999) < result) {
            result = workout.averagePace ?? 99999
        }
    }
    return result
}

function totalDistance(workouts: Workout[]) {
    return workouts.map( workout => workout.distance)
        .reduce(((total, distance) => { return total + distance}))
}

function totalTime(workouts: Workout[]) {
    return workouts.map( workout => workout.time)
        .reduce(((total, time) => { return total + time}))
}

function calculateDeltaPercent(numLastWeek: number, numPreviousWeek: number) {
    return ((numLastWeek - numPreviousWeek) / numPreviousWeek * 100)
}
