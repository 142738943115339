import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_REGRESSION_COLOR, VEGA_WIDTH } from "../vegahelpers";


export default function PaceOverDistance() {
    const data : any = useLoaderData()
    return vegaView(data, ((metadata) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [{calculate: "datum.time * 50 / datum.distance", as: "Average Pace"}],
        encoding: {
            color: {field: "comments"} ,
        },
        layer: [ 
            {
                mark: 'circle',
                title: {
                    text: "Avg. Pace vs. Distance",
                },
                encoding: {
                    x: { field: 'distance', type: 'quantitative', axis: { title: "Distance (m)"} ,
                        // scale: {domainMin: 100, domainMax: 200}
                    },
                    y: { field: 'Average Pace', type: 'quantitative', sort: "descending", axis: { formatType: 'secondsAsPace'} ,
                    scale: { domainMin: metadata.paceAxisMin, domainMax: metadata.paceAxisMax } },
                    tooltip: [
                        {field: "Average Pace", type: "quantitative", formatType: "secondsAsPace"},
                        {field: "date", type: "temporal"},
                        {field: "heart_rate.average", type: "quantitative"},
                        {field: "distance", type: "quantitative"},
                        {field: "comments"}
                    ]
                },
            },
            {
                mark: { type: "line", color: VEGA_REGRESSION_COLOR},
                transform: [
                    {
                        regression: "Average Pace",
                        on: "distance"
                    }
                ],
                encoding: {
                    x: { field: 'distance', type: 'quantitative' },
                    y: { field: 'Average Pace', type: 'quantitative', sort: "descending"},
                }
            },
            {
                mark: { 
                    type: "text", 
                    color: VEGA_REGRESSION_COLOR,
                    x: "width",
                    y: -5,
                    align: "right"
                },
                transform: [
                    {
                        regression: "Average Pace",
                        on: "distance",
                        params: true
                    },
                    {calculate: "'R²: '+format(datum.rSquared, '.2f')", as: "Params"}
                ],
                encoding: {
                    text: {type: "nominal", field: "Params"}
                }
            }
        ],
        data: { name: 'table' }, 
    }}))
}