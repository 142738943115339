import { defer, redirect } from "react-router-dom"
import { getSessionId } from "./auth"

const funcAppURL = process.env.REACT_APP_PRERGRESS_FUNC_URL

export interface UserProfile {
    id: number
    username: string
    first_name: string
    last_name: string
    gender: string
    dob: string
    email: string
    country: string
    profile_image: string
    age_restricted: boolean
    email_permission: boolean
    max_heart_rate: number
    weight: number
    roles: any
}

export interface Stroke {
    t: number // Time in tenths of a second; resets per interval
    d: number // Distance in decimeters
    p: number // Pace in tenths of a second, e.g. 971 is a pace of 1:37.1. This is pace per 500m for the rower and SkiErg, and pace per 1000m for the BikeErg.	
    spm: number // Strokes per minute
    hr: number // Heart rate
    totalTime?: number // This stroke time plus all previous intervals
    interval?: number // Which interval is this stroke part of
}

export interface HeartRate {
    average: number
    min: number
    max: number
    ending: number
    recovery: number
    rest?: number
}

export interface Workout {
    id: number
	user_id: number
	date: string // "2022-12-20 15:51:00"
	timezone: string // "America/New_York"
	date_utc: string // "2022-12-20 20:51:00"
	distance: number // meters
	type?: string // rower
	time: number // in tenths of a second; only includes work time
	time_formatted: "27:30.0"
	workout_type: string // VariableInterval
    source: string // "ErgData Android"
    weight_class: string // H
    verified: boolean
    ranked: boolean
    comments: string // includes workout name from app "3 mind"
    stroke_data: boolean
    rest_distance?: number // meters
    rest_time?: number // in tenths of a second
    calories_total: number
    drag_factor: number
    stroke_count: number
    stroke_rate: number // strokes per minute
    heart_rate?: HeartRate
    real_time?: any
    machine?: string
    workout?: WorkoutDetails
    averagePace?: number
    averageWatts?: number 
}
    
export interface WorkoutDetails {
    intervals?: [Interval]
}    

export interface Interval {
    
    type: string //time
    time: number // in tenths of a second
    distance: number // meters
    calories_total: number
    stroke_rate: number
    rest_distance: number
    rest_time: number // in tenths of a second
    heart_rate: HeartRate
    targets: Target
}

export interface Target {
    stroke_rate: number
    heart_rate_zone: number
}
            

async function apiCall(path: string, params: Map<string, string> = new Map<string, string>() ){
    const sessionId = await getSessionId()
    if (sessionId == null) {
        return redirect("/welcome")
    }

    const cachedResult = checkCache(path, params)
    if (cachedResult != null) {
        return cachedResult
    }
    var fullUrl = `${funcAppURL}${path}`
    if (params.size > 0) {
        fullUrl += '?' + ( new URLSearchParams( Object.fromEntries(params) ) ).toString();
    } 
    console.log(fullUrl)
    const response = await fetch(fullUrl, {headers: {"session-id": sessionId}})
    if (!response.ok){
        if (response.status === 401) {
            return redirect('/logout')
        }
    }
    const results = await response.json()

    addToCache(path, params, results)
    return results
}

function calculateWorkoutMetadata(workouts: [Workout]) {
    var max_avg_heart_rate = 0
    var min_avg_heart_rate = 1000
    var fastest_avg_pace = 10000000
    var slowest_avg_pace = 0
    var workoutNames : any = {}
    for (const workoutIndex in workouts) {
        const workout = workouts[workoutIndex]
        const pace = workout.time * 50 / workout.distance
        if ( workout.heart_rate != null && workout.heart_rate.average > max_avg_heart_rate) {
            max_avg_heart_rate = workout.heart_rate.average
        }
        if ( workout.heart_rate != null && workout.heart_rate.average < min_avg_heart_rate) {
            min_avg_heart_rate = workout.heart_rate.average
        }
        if (pace > slowest_avg_pace) {
            slowest_avg_pace = pace
        }
        if (pace < fastest_avg_pace) {
            fastest_avg_pace = pace
        }
        workoutNames[workout.id] = `${workout.date} ${ workout.comments != null ? "["+workout.comments+"]" : ""}`
    }
    sessionStorage.setItem("workoutNames", JSON.stringify(workoutNames))
    return { 
        heartRateAxisMin: min_avg_heart_rate * 0.98,
        heartRateAxisMax: max_avg_heart_rate * 1.02,
        paceAxisMax: slowest_avg_pace * 1.02,
        paceAxisMin: fastest_avg_pace * 0.98
    }

}

export async function loadAllWorkouts() {
    const workoutsPromise = apiCall('/results')
    const metadataPromise = workoutsPromise.then( (workouts) => {
        if (workouts instanceof Response) {
            return workouts
        }
        calculateAdditionalWorkoutFields(workouts)
        return calculateWorkoutMetadata(workouts)
    })
    return defer({data: workoutsPromise, metadata: metadataPromise })
}

export async function loadUser() {
    return apiCall('/profile') as Promise<UserProfile>
}

export async function loadWorkoutDetails( {params}: any) {
    var passedParams = new Map<string, string>()
    passedParams.set("workoutId", params.workoutId)
    return defer({data: apiCall(`/stroke_data`, passedParams).then( (strokes) => {
        if (strokes instanceof Response) {
            return strokes
        }
        calculateAddtionalStrokeFields(strokes)
        return strokes
    })})
}

function addToCache(path: string, params: Map<String, String>, object: any){
    const pathKey = `${path}-${JSON.stringify(Array.from(params.entries()))}`
    sessionStorage.setItem(pathKey, JSON.stringify(object))
    sessionStorage.setItem(`last_time_${pathKey}`, new Date().toISOString())
}

function checkCache(path: string, params: Map<String, String>): any {
    const pathKey = `${path}-${JSON.stringify(Array.from(params.entries()))}`
    const cachedString = sessionStorage.getItem(pathKey)
    const lastDateString = sessionStorage.getItem(`last_time_${pathKey}`)
    if (cachedString != null && lastDateString != null) {
        const cachedDatetime = new Date(lastDateString!)
        if (new Date().getTime() - cachedDatetime.getTime() < (30 * 60 * 1000)){
            return JSON.parse(cachedString)
        } else {
            console.log("Cache expired; refreshing")
            return null
        }
    } else {
        return null
    }
    
}

function calculateAddtionalStrokeFields(strokes: [Stroke]) {
    var previousIntervalTime = 0
    var interval = 0
    var lastStrokeTime = -1
    for (const strokeIndex in strokes) {
        var stroke = strokes[strokeIndex]
        if (stroke.t < lastStrokeTime) {
            previousIntervalTime += lastStrokeTime
            interval +=1
        }
        stroke.totalTime = stroke.t + previousIntervalTime
        stroke.interval = interval
        lastStrokeTime = stroke.t
    }
}

function calculateAdditionalWorkoutFields(workouts: [Workout]) {

    for (const workoutIndex in workouts) {
        var workout = workouts[workoutIndex]
        workout.averagePace = workout.time * 50 / workout.distance
        workout.averageWatts = 2.8 / Math.pow((workout.averagePace / 500), 3)
        if (workout.comments == null) {
            workout.comments = "None"
        }
    }
}

export function getWorkoutName(workoutId: number) {
    const workoutNames =  sessionStorage.getItem("workoutNames")
    if (workoutNames != null) {
        return JSON.parse(workoutNames)[workoutId]
    } else {
        return "<Unknown>"
    }
    
}

export function getWorkoutById(workoutId: number) {
    const cachedWorkouts : [Workout] = checkCache("/results", new Map<String, String>())
    for (const workoutIndex in cachedWorkouts) {
        const workout = cachedWorkouts[workoutIndex]
        if (workout.id === workoutId ) {
            return workout
        }
    }
    return null
}