import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";


export default function WorkoutsPerWeek() { 
    const data : any = useLoaderData()
    return vegaView(data, ((_) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        mark: 'bar',
        title: {
            text: "Workouts per Week",
        },
        encoding: {
            x: { field: 'date', type: "temporal", timeUnit:"yearweek", axis: { title: "Week of Workout", format: "%Y-%m-%d"}},
            y: { aggregate: "count" , axis: { title: "Workouts", tickMinStep: 1}},
            color: {field: "comments"},
            tooltip: [
                {aggregate: "count"},
            ]
        },
        data: { name: 'table' }, 
    }}))
}