import React from "react";
import { Link, useLocation} from "react-router-dom"
import ReactGA from "react-ga4"

const funcAppURL = process.env.REACT_APP_PRERGRESS_FUNC_URL
const env = process.env.REACT_APP_ENV

export default function AuthReturn() {

  const location = useLocation()

  React.useEffect(() => {
    // Google Analytics
    console.log("ga send auth_return")
    ReactGA.send("pageview");
  }, [location]);

    console.log("Trying to finish oauth")
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    console.log(`Got code ${code}`)
    const finishUrl = `${funcAppURL}/finish_auth_session?env=${env}&auth_code=${code}`
    fetch(finishUrl).then( (response : Response) => {
      response.text().then( (sessionId) => {
        console.log(`session_id: ${sessionId}`)
        localStorage.setItem("sessionId", sessionId)
        window.location.href = '/'
      })
    })
    return (<Link to="/" />)
  }
