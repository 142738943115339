import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_REGRESSION_COLOR, VEGA_WIDTH } from "../vegahelpers";


export default function CardioOverTime() {
    const data : any = useLoaderData()
    return vegaView(data, ((metadata) => { return{
        config: VEGA_CONFIG,
        vconcat: [{
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [
            {filter: "isDefined(datum.heart_rate) && isDefined(datum.heart_rate.average)"},
            {calculate: "datum.averageWatts / datum.heart_rate.average", as: "Cardio Efficiency"}
        ],
        layer: [ 
            {
                params: [{
                    "name": "selectedTypes",
                    "select": {"type": "point", "fields": ["comments"]},
                    "bind": "legend"
                  }],
                mark: 'circle',
                title: {
                    text: "Cardio Efficiency over Time",
                    subtitle: '"Efficiency" = avg. watts / avg. heart rate'
                },
                encoding: {
                    x: { field: 'date', type: 'temporal', axis: { title: "Date of Workout"}, 
                        scale: {domain: {param: "timeBrush"},} 
                    },
                    y: { field:  "Cardio Efficiency", type: 'quantitative'  },
                    color: {field: "comments"} ,
                    tooltip: [
                        {field: "Cardio Efficiency", type: "quantitative"},
                        {field: "date", type: "temporal"},
                        {field: "distance", type: "quantitative"}
                    ],
                    opacity: {
                        condition: {"param": "selectedTypes", "value": 1},
                        value: 0.2
                      }
                },
            },
            {
                mark: { type: "line", color: VEGA_REGRESSION_COLOR},
                transform: [
                    {filter: {param: "selectedTypes"}},
                    {filter: {param: "timeBrush"}},
                    {
                        regression:  "Cardio Efficiency",
                        on: "date"
                    }
                ],
                encoding: {
                    x: { field: 'date', type: 'temporal' , 
                    scale: {domain: {param: "timeBrush"},} },
                    y: { field:  "Cardio Efficiency", type: 'quantitative'},
                }
            },
            {
                mark: { 
                    type: "text", 
                    color: VEGA_REGRESSION_COLOR,
                    x: "width",
                    y: -5,
                    align: "right"
                },
                transform: [
                    {filter: {param: "selectedTypes"}},
                    {filter: {param: "timeBrush"}},
                    {
                        regression:  "Cardio Efficiency",
                        on: "date",
                        params: true
                    },
                    {calculate: "'R²: '+format(datum.rSquared, '.2f')", as: "Params"}
                ],
                encoding: {
                    text: {type: "nominal", field: "Params"}
                }
            }
        ],
    }, {
        "width": VEGA_WIDTH,
        "height": 60,
        "mark": "bar",
        "params": [{
          "name": "timeBrush",
          "select": {"type": "interval", "encodings": ["x"]}
        }],
        "encoding": {
          "x": {
            "field": "date",
            "type": "temporal",
            timeUnit: "yearweek",
            axis: { title: "Week of Workout", format: "%Y-%m-%d"}
          },
          "y": {
            "aggregate": "count",
            "type": "quantitative",
            "axis": {"tickCount": 2, "grid": false, title: "Workouts"}
          }
        }
      }],
        data: { name: 'table' }, 
    }}), null, "Note: This measure of 'efficiency' was made up by me and has no particular basis.  I'm not sure if it's really meaningful; it seems to correlate with pace.  Suggestsions extra welcome.")
}