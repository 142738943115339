import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";



export default function WattsOverStrokeRate() {
    const data : any = useLoaderData()
    return vegaView(data, ((_) => { return{
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [
            {calculate: " 2.8 / (pow(datum.p / 5000, 3)) ", as: "Watts"}
        ],
        mark: { type: 'circle', clip: true },
        title: {
            text: "Watts vs. Stroke Rate",
        },
        encoding: {
            x: { field: 'spm', type: "quantitative", axis: { title: "Strokes per Minute"},
                scale: { domainMin: 0, domainMax: 50}
            },
            y: { field: "Watts", type: "quantitative", axis: { tickMinStep: 1}},
            color: {field: "interval", type: "nominal"},
            tooltip: [
                {field: "Watts"},
                {field: "totalTime"}
            ]
        },
        data: { name: 'table' }, 
    }}))
}