const funcAppURL = process.env.REACT_APP_PRERGRESS_FUNC_URL

export function getWorkoutId(matches: any) {
    for (const matchIndex in matches) {
        const match = matches[matchIndex]
        if (match.params['workoutId'] != null) {
        return parseInt(match.params['workoutId'])
        }
  }
  return null
}


export async function get_inspiration() {
  const key = `inspiration-${new Date().toISOString().slice(0, 10)}`
  const existingInspiration = sessionStorage.getItem(key)
  if (existingInspiration != null){
    return existingInspiration
  } else {
    const finishUrl = `${funcAppURL}/get_inspiration`
    const response = await fetch(finishUrl)
    const content = await response.text()
    sessionStorage.setItem(key, content)
    return content
  }
}


