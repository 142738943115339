import { Suspense } from "react"
import { Await } from "react-router-dom"
import { ClimbingBoxLoader } from "react-spinners"
import { VegaLite, VisualizationSpec } from "react-vega"
import { expressionFunction } from "vega"
import { getWorkoutById } from "./concept2"

export const VEGA_WIDTH=800
export const VEGA_HEIGHT=500
export const VEGA_REGRESSION_COLOR="#223066"

export function tenthsOfSecondToMinutes(tenths: number){
    return `${Math.floor(tenths / 600)}`
}


export function strokeTimeAsMinutesSeconds(tenthSeconds: number) {
    return `${Math.floor(tenthSeconds / 600)}:` + `${(tenthSeconds % 600) / 10}`.padStart(2, '0')
}

expressionFunction('strokeTimeAsMinutesSeconds', function(datum : number, _ :any){
    return strokeTimeAsMinutesSeconds(datum)
})

export function secondsAsPace (seconds: number) {
    return `${Math.floor(seconds / 60)}:` + `${(seconds % 60).toFixed(3)}`.padStart(2, '0')
}

expressionFunction('secondsAsPace', function(datum : number, _ :any){
    return secondsAsPace(datum)
})

export const VEGA_CONFIG = {
    font: "Barlow Condensed",
    customFormatTypes: true,
    title: {
        fontSize: 40,
        subtitleFontSize: 20
    },
    axis: {
        titleFontSize: 25,
        labelFontSize: 15,
        gridColor: "#f0f0f0"
    },
    mark: {
        color: "#574f6e"
    },
    circle: {
        size: 200,
        filled: false
    },
    legend: {
        titleFontSize: 20,
        labelFontSize: 15
    }
}

export function vegaView(loaderData: any, vegaLiteSpecFunc: ( metadata: any ) => VisualizationSpec, workoutId: number | null = null, captionText: string = ""){
    
    const workout =  workoutId != null ? getWorkoutById(workoutId) : null

    return (
        
    <div>
        <Suspense fallback={
                <div>
                    <h1>Loading workout data...</h1>
                    <ClimbingBoxLoader color="#574f6e"/>
                    </div>
                }>
            <Await resolve={Promise.all([loaderData.data, loaderData.metadata])}>
                {(resolvedData) => {
                    // console.log(resolvedData)
                    const data = { "table" : resolvedData[0]}
                    const metadata : any  = resolvedData[1]
                    const vegaLiteSpec = vegaLiteSpecFunc(metadata)
                    return (<VegaLite spec={vegaLiteSpec} data={data}/>)
                }}
            </Await>
        </Suspense>
        { workoutId &&
            <div>
            Date: {workout?.date} <br/>
            Distance: {workout?.distance}<br/>
            Comments: {workout?.comments}<br/>
            </div>
          }
          <div><b>
            {captionText}</b>
          </div>
    </div>
    );
}