import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_REGRESSION_COLOR, VEGA_WIDTH } from "../vegahelpers";


export default function WattsOverHeartRate() {
    const data : any = useLoaderData()
    return vegaView(data, ((metadata) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [
            {filter: "isDefined(datum.heart_rate) && isDefined(datum.heart_rate.average)"},
        ],
        layer: [ 
            {
                mark: 'circle',
                title: {
                    text: "Avg. Watts vs. Avg. Heart Rate",
                    subtitle: "Cardiovascular Efficiency"
                },
                encoding: {
                    x: { field: 'heart_rate.average', type: 'quantitative', axis: { title: "Average Heart Rate"} ,
                        scale: {domainMin: metadata.heartRateAxisMin, domainMax: metadata.heartRateAxisMax}
                    },
                    y: { field: 'averageWatts', type: 'quantitative', axis: { title: "Average Watts"} },
                    tooltip: [
                        {field: "averageWatts", type: "quantitative"},
                        {field: "date", type: "temporal"},
                        {field: "heart_rate.average", type: "quantitative"},
                        {field: "distance", type: "quantitative"}
                    ]
                },
            },
            {
                mark: { type: "line", color: VEGA_REGRESSION_COLOR},
                transform: [
                    {
                        regression: "averageWatts",
                        on: "heart_rate.average"
                    }
                ],
                encoding: {
                    x: { field: 'heart_rate.average', type: 'quantitative' },
                    y: { field: 'averageWatts', type: 'quantitative'},
                }
            },
            {
                mark: { 
                    type: "text", 
                    color: VEGA_REGRESSION_COLOR,
                    x: "width",
                    y: -5,
                    align: "right"
                },
                transform: [
                    {
                        regression: "averageWatts",
                        on: "heart_rate.average",
                        params: true
                    },
                    {calculate: "'R²: '+format(datum.rSquared, '.2f')", as: "Params"}
                ],
                encoding: {
                    text: {type: "nominal", field: "Params"}
                }
            }
        ],
        data: { name: 'table' }, 
    }}))
}