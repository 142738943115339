import { useLoaderData } from "react-router-dom";
import { VegaLite, VisualizationSpec } from "react-vega";
import { UserProfile } from "../concept2";
import { VEGA_CONFIG } from "../vegahelpers";


export default function User() {

    const user : UserProfile = useLoaderData() as UserProfile
    

    // The hidden chart on this page is a goofy hack for getting the font to load right the first time...
    const data = { table: [{x: 1, y: 1}]}
    const spec : VisualizationSpec = { config: VEGA_CONFIG, mark: 'point', encoding: { x: {field: "x"}, y: {field: "y"}}, data: { name: 'table' }, }

    return (
    <div >
        <div id="contact">
        <img
            key={user.profile_image}
            src={user.profile_image || "foo"}
            alt="You"
        />
        </div>

        <div>
        <h1>
            <>
                {user.first_name} {user.last_name}
            </>
        </h1>
        <p>{user.dob}</p>
        <p>{user.country}</p>
        </div>
        <div hidden={true}>
            <VegaLite spec={spec} data={data}/>
        </div>
    </div>
    );
}