import React from 'react';
import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom';

export default function Welcome() {
  const location = useLocation()

  React.useEffect(() => {
    // Google Analytics
    console.log("ga send welcome")
    ReactGA.send("pageview");
  }, [location]);
  return (
    <div id='welcome'>
        <h1>
          Let's make prergress! Please log in.
        </h1>
        <p>
          If you are not already logged in to logbook, you may need to return to this page and authorize again.
        </p>
        <button onClick={login}>Login to c2</button>
    </div>
  );
}

async function login(){
  const funcAppURL = process.env.REACT_APP_PRERGRESS_FUNC_URL
  const env = process.env.REACT_APP_ENV
  window.location.href = `${funcAppURL}/start_auth?env=${env}`;
}




