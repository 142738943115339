import { useLoaderData, useMatches } from "react-router-dom";
import { getWorkoutId } from "../helpers";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";



export default function WattsDuringWorkout() {
    const data : any = useLoaderData()

    const workoutId = getWorkoutId(useMatches())

    return vegaView(data, ((metadata) => { return{
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        transform: [
            {calculate: " 2.8 / (pow(datum.p / 5000, 3)) ", as: "Watts"}
        ],
        mark: 'bar',
        title: {
            text: "Watts during Workout",
            subtitle: "Time in workout excludes rest"
        },
        encoding: {
            x: { field: 'totalTime', type: "quantitative", axis: { title: "Time in Workout", formatType: 'strokeTimeAsMinutesSeconds'},
                scale: { domainMin: 0}
            },
            y: { field: "Watts", type: "quantitative", axis: { tickMinStep: 1}},
            tooltip: [
                {field: "Watts"},
                {field: "totalTime"}
            ]
        },
        data: { name: 'table' }, 
    }}), workoutId)
}