

export interface AuthData {
    access_token: string
    token_type: string
    expires_in: number
    refresh_token: string
    expiration_datetime?: string
}

export async function getSessionId() {
    return localStorage.getItem('sessionId')
}

export function getCachedSessionId() {
    return localStorage.getItem('sessionId')
}

export function isLoggedIn() {
    return localStorage.getItem('sessionId') != null
}