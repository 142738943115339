import React from "react";
import { Navigate, useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

export default function Logout(){
    const location = useLocation()

    React.useEffect(() => {
      // Google Analytics
      console.log("ga send logout")
      ReactGA.send("pageview");
    }, [location]);

    localStorage.clear()
    sessionStorage.clear()
    return (<div><Navigate to="/welcome" /></div>)
}